function MediaController() { }
function UserController() { }
function AuthController() { }
function LocationController() { }
function CustomerController() { }
function UserCustomerController() { }
function CampaignController() { }
function YearController() { }

CampaignController.index = function ($scope, $window, Campaigns, Customer) {

    Campaigns.query({
        customer: Customer.id
    }, function (campaigns) {
        $scope.campaigns = campaigns;
    });
    sessionStorage.removeItem('bookings_' + Customer.id);
    $scope.showArchived = false;

    $scope.remove = function (id) {
        var campaignKey;
        var deleteCampaign;

        angular.forEach($scope.campaigns, function (campaign, key) {
            if (campaign.id == id) {
                campaignKey = key;
                deleteCampaign = campaign;
            }
        });
        if (campaignKey === undefined || !deleteCampaign || !$window.confirm("Are you sure you want to delete '" + deleteCampaign.title + "'?")) {
            return;
        }
        $scope.campaigns[campaignKey].$delete(function () {
            $scope.campaigns.splice(campaignKey, 1);
        });
    };
}

/**
 * The state you enter when clicking a booking in the overview.
 */
CampaignController.show = function ($scope, $stateParams, $window, $http, Campaigns, Bookings, Media, Customer) {

    $scope.datepickers = {};
    $scope.selectedBookings = {};
    $scope.selectAll = false;
    // The bookings need to be in an array for the filter to work. But it is
    // very convenient to have a list keyed by ID.
    $scope.bookings = {};
    $scope.bookingsArray = [];
    $scope.bookingCount = 0;
    $scope.media = {};

    if ($window.location.search) {
        $scope.search = $stateParams.search;
    }
    Campaigns.get($stateParams, function (campaign) {
        $scope.campaign = campaign;
    });
    Media.query({ customer: Customer.id }, function (media) {
        for (var i = 0; i < media.length; i++) {
            var medium = media[i];
            $scope.media[medium.id] = medium;
        }

        Bookings.query({ campaign: $stateParams.id }, function (bookings) {
            $scope.bookingCount = bookings.length;
            for (var key = 0; key < bookings.length; key++) {
                var booking = bookings[key];
                $scope.bookings[booking.id] = booking;

                // We add the media object to make it searchable.
                booking.mediaSearch = $scope.media[booking.m];
                $scope.bookingsArray.push(booking);
                $scope.selectedBookings[booking.id] = false;
            }
        });
    });

    function downloadFile(fileName, data, strMimeType) {
        var D = document;
        var a = D.createElement('a');
        strMimeType = strMimeType || 'application/octet-stream;charset=utf-8';
        var rawFile;

        // IE10+
        if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(new Blob([data], {
                type: strMimeType
            }), fileName);
        }

        //html5 A[download]
        if ('download' in a) {
            var blob = new Blob([data], {
                type: strMimeType
            });
            rawFile = URL.createObjectURL(blob);
            a.setAttribute('download', fileName);
        } else {
            rawFile = 'data:' + strMimeType + ',' + encodeURIComponent(data);
            a.setAttribute('target', '_blank');
        }

        a.href = rawFile;
        a.setAttribute('style', 'display:none;');
        D.body.appendChild(a);
        setTimeout(function () {
            if (a.click) {
                a.click();
                // Workaround for Safari 5
            } else if (document.createEvent) {
                var eventObj = document.createEvent('MouseEvents');
                eventObj.initEvent('click', true, true);
                a.dispatchEvent(eventObj);
            }
            D.body.removeChild(a);

        }, 100);
    }

    $scope.saveSelectedFiles = function () {
        var downloads = [];

        angular.forEach($scope.selectedBookings, function (chosen, id) {
            if (chosen) {
                var booking = $scope.bookings[id];
                var filename = $scope.media[booking.m].location.customer.id + '_' + booking.m + '.pdf';
                var url = '/media_pdf/' + filename + '?' + new Date().getTime();

                if (!downloads[url]) {
                    $http.get(url, { responseType: 'arraybuffer' }).then(function (response) {
                        downloadFile(filename, response.data, 'application/pdf');
                    });
                }

                downloads[url] = true;
            }
        });
    };

    // Confirm a booking and clear the booking cache.
    $scope.confirm = function (key) {
        var booking = new Bookings()
        booking.id = key;
        booking.confirmed = !$scope.bookings[key].co;

        var mediaId = $scope.bookings[key].m;
        sessionStorage.removeItem('bookings_' + $scope.media[mediaId].location.customer.id);

        booking.$update(function (booking) {
            $scope.bookings[key].co = booking.co;
        },
            function (error) {
                if (error.data.errors) {
                    $window.alert(error.data.errors[0]);
                }
            });
    };

    $scope.changeAll = function (newStatus) {
        if (!newStatus) {
            newStatus = $scope.selectAll;
        }
        angular.forEach($scope.selectedBookings, function (status, key) {
            $scope.selectedBookings[key] = newStatus;
        });
    };

    // Delete bookings selected
    $scope.deleteSelected = function () {
        if (!$window.confirm('Are you sure?')) {
            return true;
        }

        $scope.selectAll = false;
        angular.forEach($scope.bookings, function (booking, key) {
            if ($scope.selectedBookings[booking.id]) {
                delete $scope.selectedBookings[booking.id];
                $scope.bookings[key].$delete(function () {
                    delete $scope.bookings[key];
                    deleteFromBookingsArray(key);
                    $scope.bookingCount--;
                });
            }
        });
    };

    $scope.save = function (key) {
        var booking = angular.extend({}, $scope.bookings[key]);
        var saveBooking = {
            id: booking.id,
            campaign: booking.cam.id,
            media: booking.m,
            confirmed: booking.co,
            cardinality: booking.ca
        };
        if (booking.u) {
            saveBooking.user = booking.u.i
        }
        saveBooking.period = {};

        if (booking.p.start instanceof Date) {
            saveBooking.period.start = moment(booking.p.start).format('YYYY-MM-DD');
            booking.p.start = moment(booking.p.start).format('YYYY-MM-DD');
        } else {
            saveBooking.period.start = booking.p.start;
        }

        if (booking.p.end instanceof Date) {
            saveBooking.period.end = moment(booking.p.end).format('YYYY-MM-DD');
            booking.p.end = moment(booking.p.end).format('YYYY-MM-DD');
        } else {
            saveBooking.period.end = booking.p.end;
        }

        Bookings.save(saveBooking,
            function (booking) {
                $scope.bookings[key] = booking;
            },
            function (error) {
                if (error.data.errors) {
                    $window.alert(error.data.errors[0]);
                }
            }
        );
    }

    $scope.remove = function (key) {
        if (!$window.confirm('Are you sure?')) {
            return true;
        }
        clearCache();
        $scope.bookings[key].$delete(function () {
            delete $scope.bookings[key];
            deleteFromBookingsArray(key);
            $scope.bookingCount--;
        });
    }

    $scope.editDate = function (key, oldDate) {
        $scope.bookings[key].period.end = moment($scope.bookings[key].period.end).add(moment($scope.bookings[key].period.start).diff(moment(oldDate))).format('YYYY-MM-DD');
    }

    function deleteFromBookingsArray(key) {
        for (var i = 0; i < $scope.bookingsArray.length; i++) {
            if ($scope.bookingsArray[i].id == null || $scope.bookingsArray[i].id == key) {
                $scope.bookingsArray.splice(i, 1);
            }
        }
    }
};

CampaignController.edit = function ($scope, $state, $http, $window, $stateParams, Campaigns) {
    $scope.campaign = Campaigns.get($stateParams);
    $scope.errors = {};
    $scope.file = null;
    $scope.image = null;
    $scope.testib = null;

    $scope.$watch('campaign', function (campaign) {
        if (!campaign) return;
        campaign.totalBudget = parseInt(campaign.displayPrice) + parseInt(campaign.otherCosts);
    }, true);

    $scope.submit = function () {
        $scope.campaign.$save(
            function () {
                $scope.errors = {};
                $state.go('^', {}, { reload: true });
            },
            function (response) {
                $scope.errors = response.data;
            }
        )
    };

    $scope.imageSelected = function (image) {
        $scope.image = image.files[0];
    }

    $scope.fileSelected = function (file) {
        $scope.file = file.files[0];
    }

    $scope.coverImage = function () {
        var data = new FormData();
        data.append('file', $scope.image)
        $http
            .post('/api/campaign/' + $scope.campaign.id + '/image', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(
                function (response) {
                    $scope.campaign.images.push(response.data)
                    $http.post('/api/campaign/' + $scope.campaign.id + '/print');
                }, function (error) {
                    var alert = '';
                    angular.forEach(error.data.children, function (child) {
                        angular.forEach(child.errors, function (errorStr) {
                            alert += errorStr + '\n';
                        });
                    });
                    $window.alert(alert);
                }
            )
            ;
    }

    $scope.upload = function () {
        var data = new FormData();
        data.append('file', $scope.file)

        $http.post('/api/campaign/' + $scope.campaign.id + '/file', data, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        }).then(
            function (response) {
                $scope.campaign.files.push(response.data)
            }, function (error) {
                var alert = '';
                angular.forEach(error.data.children, function (child) {
                    angular.forEach(child.errors, function (errorStr) {
                        alert += errorStr + '\n';
                    });
                });
                $window.alert(alert);
            });

    }

    $scope.removeImage = function (key) {
        if (!$window.confirm('Are you sure?')) {
            return;
        }

        var image = $scope.campaign.images[key];

        Campaigns.deleteImage({ id: $scope.campaign.id, image: image.id }, function (image) {
            $scope.campaign.images.splice(key, 1)
        });
    };

    $scope.removeFile = function (key) {
        if (!$window.confirm('Are you sure?')) {
            return;
        }

        var file = $scope.campaign.files[key];

        Campaigns.deleteFile({ id: $scope.campaign.id, file: file.id }, function (file) {
            $scope.campaign.files.splice(key, 1)
        });
    };

    $scope.link = {};

    $scope.addLinkActive = false;
    $scope.addLinkLocked = false;

    $scope.toggleAddLink = function () {
        $scope.addLinkActive = true;
    };

    $scope.addLink = function () {
        $scope.addLinkLocked = true;

        var obj = {
            name: $scope.link.name,
            url: $scope.link.url,
        };

        $http.post('/api/campaign/' + $scope.campaign.id + '/link', obj).then(function (link) {
            $scope.addLinkActive = false;
            $scope.addLinkLocked = false;
            $scope.campaign.links.push(link.data);
            $scope.link = {}
        });
    }

    $scope.removeLink = function (link) {
        $http.delete('/api/campaign/' + $scope.campaign.id + '/link/' + link.id).then(function (r) {
            $scope.campaign.links = r.data;
        });
    };
}

CampaignController.create = function ($scope, $state, Campaigns, Customer) {
    $scope.campaign = new Campaigns;
    $scope.campaign.customer = Customer.id;

    $scope.errors = {};

    $scope.$watch('campaign', function (campaign) {
        if (!campaign) return;
        campaign.totalBudget = parseInt(campaign.displayPrice) + parseInt(campaign.otherCosts);
    }, true);

    $scope.submit = function (redirectToEdit) {
        $scope.campaign.$save(
            function (response) {
                $scope.errors = {}
                sessionStorage.removeItem('campaigns_' + Customer.id);
                if (redirectToEdit) {
                    $state.go('campaign.edit', { id: response.id }, { reload: true })
                } else {
                    $state.go('campaign.index', {}, { reload: true })
                }
            },
            function (response) {
                $scope.errors = response.data;
            }
        )
    }
}

UserCustomerController.index = function ($scope, $window, UserCustomers, Customer) {
    $scope.email;

    UserCustomers.query({ customer: Customer.id }, function (userCustomers) {
        $scope.userCustomers = userCustomers;
    })

    $scope.invite = function () {
        UserCustomers.invite(
            {
                customer: Customer.id,
                email: $scope.email
            },
            function (userCustomer) {
                $scope.error = '';
                $scope.userCustomers.push(userCustomer)
            },
            function (response) {
                $scope.error = response.data.children.email.errors[0];
            }
        )
    };

    $scope.remove = function (id) {
        var deleteKey;
        var deleteEntity;

        angular.forEach($scope.userCustomers, function (userCustomer, key) {
            if (userCustomer.id == id) {
                deleteKey = key;
                deleteEntity = userCustomer;
            }
        });

        if (deleteKey === undefined || !deleteEntity || !$window.confirm("Are you sure you want to delete '" + deleteEntity.user.email + "' from " + deleteEntity.customer.title + "?")) {
            return;
        }

        $scope.userCustomers[deleteKey].$delete(function () {
            $scope.userCustomers.splice(deleteKey, 1);
        });
    }
}

UserCustomerController.show = function ($scope, $stateParams, UserCustomers) {
    $scope.userCustomer = UserCustomers.get($stateParams)
}

UserCustomerController.edit = function ($scope, $stateParams, $state, UserCustomers, Locations, Customer) {
    $scope.errors = {};
    $scope.locations = [];
    $scope.userCustomer = {};
    $scope.userCustomerLocations = [];

    $scope.click = function (location) {
        var key = $scope.userCustomerLocations.indexOf(location.id)

        if (key >= 0) {
            $scope.userCustomerLocations.splice(key, 1);

            return;
        }

        $scope.userCustomerLocations.push(location.id)
    }

    Locations.query({ customer: Customer.id }, function (locations) {
        $scope.locations = locations
    })

    UserCustomers.get($stateParams, function (userCustomer) {
        $scope.userCustomer = userCustomer;

        angular.forEach(userCustomer.locations, function (location) {
            $scope.userCustomerLocations.push(location.id);
        });
    })


    $scope.submit = function () {
        var userCustomer = $scope.userCustomer;
        userCustomer.user = $scope.userCustomer.user.id;
        userCustomer.locations = $scope.userCustomerLocations;
        userCustomer.customer = $scope.userCustomer.customer.id;

        userCustomer.$save(
            function () {
                $state.go('^', {}, { reload: true })
            },
            function (response) {
                $scope.errors = response.data;
            }
        )
    }
};

MediaController.index = function ($scope, $window, $modal, Media, Customer) {
    $scope.medias = Media.query({ customer: Customer.id }, function (media) {
        sessionStorage.setItem('media_' + Customer.id, JSON.stringify(media));
    });

    $scope.remove = function (id) {
        var deleteKey;
        var deleteEntity;
        angular.forEach($scope.medias, function (medium, key) {
            if (medium.id == id) {
                deleteKey = key;
                deleteEntity = medium;
            }
        });

        if (deleteKey === undefined || !deleteEntity || !$window.confirm("Are you sure you want to delete " + deleteEntity.title + " on " + deleteEntity.location.title + "?")) {
            return;
        }

        $scope.medias[deleteKey].$delete(function () {
            $scope.medias.splice(deleteKey, 1);
        });
    };

    $scope.duplicateMedia = function (media) {
        $modal.open({
            templateUrl: 'duplicate_media.html',
            controller: 'MediaController.duplicateModal',
            resolve: {
                selectedMedia: function () {
                    return media;
                }
            }
        });

    };
};

MediaController.duplicateModal = function ($scope, $window, Locations, Media, Customer, selectedMedia) {
    $scope.locations = [];
    $scope.selectedLocation = {};
    $scope.loading = false;

    Locations.query({ customer: Customer.id }, function (l) {
        $scope.locations = l;
    });

    $scope.submit = function (closeTrigger, location) {
        $scope.loading = true;
        selectedMedia.id = null;
        selectedMedia.location = typeof location.selected === 'undefined' ? null : location.selected.id;
        selectedMedia.duplicateFiles = true;

        Media.save(selectedMedia,
            function () {
                $window.location.reload();
            },
            function (response) {
                $scope.error = "Please select a location";
                $scope.loading = false;
            }
        )
    }
};

MediaController.show = function ($scope, $stateParams, $window, $compile, Media, $http) {
    $scope.media = Media.get($stateParams);
    $scope.timestamp = new Date().getTime();

    $scope.exportPDF = function (id, event) {
        $http.post('/api/media/' + id + '/print', {});
    }

    if (window.location.pathname.match(/(\/print\b)$/i)) {
        angular.element(document.getElementsByClassName("footer")).remove();
    }
};

MediaController.edit = function ($scope, $stateParams, $http, $window, Media) {
    $scope.period = { start: null, end: null }
    $scope.file = null;
    $scope.image = null;

    Media.get($stateParams, function (media) {
        $scope.media = media;
        $scope.location = media.location.id;
        $scope.period = media.period;
    });

    $scope.removeImage = function (key) {
        if (!$window.confirm('Are you sure?')) {
            return;
        }

        var image = $scope.media.images[key];

        Media.deleteImage({ id: $scope.media.id, image: image.id }, function (image) {
            $scope.media.images.splice(key, 1)
        })

    }

    $scope.removeFile = function (key) {

        if (!$window.confirm('Are you sure?')) {
            return;
        }

        var file = $scope.media.files[key];

        Media.deleteFile({ id: $scope.media.id, file: file.id }, function (file) {
            $scope.media.files.splice(key, 1)
        })
    }

    $scope.primaryImage = function (key) {
        var image = $scope.media.images[key];

        Media.primaryImage({ id: $scope.media.id, image: image.id }, function (image) {
            $scope.media.image = image;
        })
    }

    $scope.coverImage = function () {
        var data = new FormData();
        data.append('file', $scope.image)
        $http
            .post('/api/media/' + $scope.media.id + '/image', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(
                function (response) {
                    $scope.media.images.push(response.data)
                    $http.post('/api/media/' + $scope.media.id + '/print');
                }, function (error) {
                    var alert = '';
                    angular.forEach(error.data.children, function (child) {
                        angular.forEach(child.errors, function (errorStr) {
                            alert += errorStr + '\n';
                        });
                    });
                    $window.alert(alert);
                }
            )
            ;
    }

    $scope.upload = function () {
        var data = new FormData();
        data.append('file', $scope.file)

        $http
            .post('/api/media/' + $scope.media.id + '/file', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(
                function (response) {
                    $scope.media.files.push(response.data)
                }
            )
            ;
    }
}

MediaController.create = function ($scope, Media) {
    $scope.period = { start: null, end: null }
    $scope.media = {
        id: null,
        title: '',
        contacts: [],
        traffic: 0,
        count: 1,
        worth: 0,
        period: {
            start: null,
            end: null
        },
        location: {
            id: null
        },
        measurement: {
            height: null,
            width: null,
            unit: 'm'
        }
    }
}

MediaController.form = function ($scope, $state, Locations, Media, Customer, TYPES, CATEGORIES) {
    $scope.errrors = {}
    $scope.units = ['m', 'cm', 'mm', 'px']

    Locations.query({ customer: Customer.id }, function (l) {
        $scope.locations = l;
    });

    $scope.datepickers = {
        start: false,
        end: false
    }

    $scope.types = TYPES;
    $scope.categories = CATEGORIES;

    $scope.add = function () {
        $scope.media.contacts.push(new Contact);
    }

    $scope.remove = function (key) {
        $scope.media.contacts.splice(key, 1)
    }

    $scope.submit = function (redirectToEdit) {
        var media = $scope.media
        media.location = $scope.location
        sessionStorage.removeItem('media_' + Customer.id);

        if ($scope.period.start instanceof Date) {
            media.period.start = moment($scope.period.start).format('YYYY-MM-DD');
        }

        if ($scope.period.end instanceof Date) {
            media.period.end = moment($scope.period.end).format('YYYY-MM-DD');
        }

        Media.save(media,
            function (medium) {
                if (redirectToEdit) {
                    $state.go('media.edit', { id: medium.id }, { reload: true })
                } else {
                    $state.go('media.list', {}, { reload: true })
                }
            },
            function (response) {
                $scope.errors = response.data
            }
        )
    }
}

AuthController.login = function ($scope, $state, $auth, Customer, Users) {
    $scope.customer = Customer;
    $scope.error = '';
    $scope.credentials = {
        username: '',
        password: ''
    }

    $scope.login = function () {
        $auth.login($scope.credentials)
            .then(function () {
                localStorage.setItem('uid', $auth.getPayload().id);

                $state.go('index', {}, { reload: true });
            })
            .catch(function (response) {
                $scope.error = response.data
            })
            ;
    }
};

AuthController.logout = function ($state, $auth) {
    clearCache();
    localStorage.clear();
    $auth.logout();

    $state.go('login', {}, { reload: true })
}

LocationController.index = function ($scope, $state, $window, Locations, Customer) {
    $scope.locations = Locations.query({
        customer: Customer.id
    }, function (locations) {
        sessionStorage.setItem('locations_' + Customer.id, JSON.stringify(locations));
    });

    $scope.copy = function (id) {
        $state.go('location.new', { id: id, abe: 'mumz' }, { reload: true });
    };
    $scope.remove = function (id) {
        var deleteKey;
        var deleteEntity;

        angular.forEach($scope.locations, function (location, key) {
            if (location.id == id) {
                deleteKey = key;
                deleteEntity = location;
            }
        });

        if (deleteKey === undefined || !deleteEntity || !$window.confirm("Are you sure you want to delete '" + deleteEntity.title + "'?")) {
            return;
        }

        clearCache();
        $scope.locations[deleteKey].$delete(function () {
            $scope.locations.splice(deleteKey, 1);
        });
    }
}

LocationController.show = function ($scope, $stateParams, Locations, Media) {
    Locations.get($stateParams, function (location) {
        $scope.location = location;
    });

    Media.query({ 'location': $stateParams.id }, function (media) {
        $scope.media = media;
    });
}

LocationController.edit = function ($scope, $state, $stateParams, Locations, Countries, Customer) {
    $scope.Countries = Countries;
    $scope.errors = {};
    $scope.location = Locations.get($stateParams);

    $scope.add = function () {
        $scope.location.contacts.push(new Contact)
    }

    $scope.remove = function (key) {
        $scope.location.contacts.splice(key, 1)
    }

    $scope.submit = function () {
        var location = $scope.location
        location.customer = Customer.id
        sessionStorage.removeItem('locations_' + Customer.id);
        location.$save(
            function () {
                $state.go('^', {}, { reload: true })
            },
            function (response) {
                $scope.errors = response.data
            }
        )
    }
}

LocationController.create = function ($scope, $state, $stateParams, Locations, Media, Countries, Customer) {
    $scope.errors = {};
    $scope.Countries = Countries;
    $scope.location = new Location;
    $scope.media = {};
    console.log($stateParams.id, typeof $stateParams.id);
    if (typeof $stateParams.id !== 'undefined' && ([0, ''].indexOf($stateParams.id) < 0)) {
        Media.query({ 'location': $stateParams.id }, function (media) {
            $scope.media = media;
        });

        $scope.location = Locations.get($stateParams);
    }

    $scope.add = function () {
        $scope.location.contacts.push(new Contact);
    }

    $scope.remove = function (key) {
        $scope.location.contacts.splice(key, 1)
    }

    $scope.submit = function () {
        var location = $scope.location
        var mediaArray = $scope.media;
        location.id = 0;
        location.customer = Customer.id
        sessionStorage.removeItem('locations_' + Customer.id);
        Locations.save(location, function (response) {
            $scope.saved = 0;

            angular.forEach(mediaArray, function (media) {
                media.location = response.id;
                media.id = null;

                Media.save(media, function (r) {
                    saved++;
                }, function (e) {
                    saved++;
                });
            });


            $scope.$watch('saved', function () {
                $state.go('^', { id: response.id }, { reload: true });
            });
        }, function (response) {
            $scope.errors = response.data
        })
    }
}

UserController.create = function ($scope, $state, Users) {
    $scope.password = { first: null, second: null };
    $scope.user = {}
    $scope.errors = {}

    $scope.submit = function () {
        var user = $scope.user
        user.password = $scope.password;

        Users.save(user, function () {
            $state.go('^', {}, { reload: true })
        }, function (response) {
            $scope.errors = response.data;
        });
    }
}

UserController.index = function ($scope, $window, Users) {
    $scope.users = Users.query();

    $scope.remove = function (id) {
        var deleteKey;
        var deleteEntity;

        angular.forEach($scope.users, function (user, key) {
            if (user.id == id) {
                deleteKey = key;
                deleteEntity = user;
            }
        });

        if (deleteKey === undefined || !deleteEntity || !$window.confirm("Are you sure you want to delete '" + deleteEntity.email + "'?")) {
            return;
        }

        $scope.users[deleteKey].$delete(function () {
            $scope.users.splice(deleteKey, 1);
        });

        if (!$window.confirm('Are you sure?')) {
            return
        }
    }
}

UserController.edit = function ($scope, $state, $stateParams, $window, Users, Customers, UserCustomers) {
    $scope.customers = [];
    $scope.user = {}
    $scope.userCustomer = {};
    $scope.password = { first: null, second: null }
    $scope.usedCustomers = [];

    Users.get($stateParams, function (user) {
        $scope.user = user;
        $scope.userCustomer.user = user.id
    });

    Customers.query(function (customers) {
        $scope.customers = customers;
    });

    $scope.add = function (key) {
        var index = $scope.usedCustomers.indexOf($scope.userCustomer.customer.id);
        if (index >= 0) {
            return;
        }

        UserCustomers.save($scope.userCustomer, function (userCustomer) {
            $scope.user.customers.push(userCustomer);
            $scope.usedCustomers.push(userCustomer.id)
        })
    }

    $scope.remove = function (key) {
        var userCustomer = $scope.user.customers[key];

        if (!$window.confirm('Are you sure?')) {
            return;
        }

        UserCustomers.delete({ id: userCustomer.id }, function () {
            var index = $scope.usedCustomers.indexOf(userCustomer.customer.id);

            if (index >= 0) {
                $scope.usedCustomers.splice(index, 1)
            }

            $scope.user.customers.splice(key, 1)
        })
    }

    $scope.submit = function () {
        var user = $scope.user;
        user.password = $scope.password;

        user.$save(
            function () {
                $state.go('^', {}, { reload: true })
            },
            function (response) {
                $scope.errors = response.data;
            }
        )
    };
}

CustomerController.show = function ($scope, $stateParams, Customers) {
    $scope.customer = Customers.get($stateParams);
}

CustomerController.edit = function ($scope, $state, $stateParams, Customers, Locales) {
    $scope.customer = Customers.get($stateParams);
    $scope.Locales = Locales;

    $scope.submit = function () {
        $scope.customer.$save(
            function (response) {
                $state.go('^', {}, { reload: true })
            },
            function (response) {
                $scope.errors = response.data
            }
        );
    }
}

CustomerController.index = function ($scope, $window, Customers) {
    $scope.customers = Customers.query();

    $scope.remove = function (id) {
        var deleteKey;
        var deleteEntity;

        angular.forEach($scope.customers, function (customer, key) {
            if (customer.id == id) {
                deleteKey = key;
                deleteEntity = customer;
            }
        });

        if (deleteKey === undefined || !deleteEntity || !$window.confirm("Are you sure you want to delete '" + deleteEntity.title + "'?")) {
            return;
        }

        $scope.customers[deleteKey].$delete(function () {
            $scope.customers.splice(deleteKey, 1);
        });
    }
}

CustomerController.brand = function ($scope, $http) {
    $scope.errors = {};
    $scope.file = null;

    $scope.upload = function () {
        var data = new FormData();
        data.append('file', $scope.file)

        $http
            .post('/api/customer/' + $scope.customer.id + '/upload', data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(
                function (response) {
                    $scope.customer.brand = response.data.brand + '?' + new Date().getTime();
                    $scope.errors = {}
                },
                function (response) {
                    $scope.errors = response.data
                }
            )
            ;
    }
}

CustomerController.create = function ($scope, $state, $stateParams, Customers, Locales) {
    $scope.customer = new Customer();
    $scope.Locales = Locales
    $scope.themes = ['default', 'redish', 'bookish', 'orangish']

    $scope.submit = function () {
        Customers.save($scope.customer, function (response) {
            $state.go('^', {}, { reload: true })
        }, function (response) {
            $scope.errors = response.data
        })
    }
}

YearController.index = function ($scope, $window, Customer) {

}
