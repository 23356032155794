;(function (angular) {
    'use strict';

    angular.module('CamPlan.admin', ['ui.router'])
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('admin', { parent : 'secure', url : '/admin', abstract : true, templateUrl : 'admin.html', params : {
                    admin : true
                }})

                .state('customer',      { url : '/customer',  controller : 'CustomerController.index',    templateUrl : 'customers.html', parent : 'admin' })
                .state('customer.new',  { url : '/new',       controller : 'CustomerController.create',  templateUrl : 'new_customer.html'})
                .state('customer.show', { url : '/{id}', abstract : true, template : '<ui-view />'})
                .state('customer.edit', { url : '/{id}/edit', controller : 'CustomerController.edit', templateUrl : 'edit_customer.html'})

                .state('customer.show.media',      { url : '/media', controller : 'MediaController.index', templateUrl : 'locations.html', parent : 'admin' })
                .state('customer.show.media.new',  { url : '/new',       controller : 'MediaController.create',  templateUrl : 'new_location.html'})
                .state('customer.show.media.show', { url : '/{id}',      controller : 'MediaController.show',     templateUrl : 'location.html'})
                .state('customer.show.media.edit', { url : '/{id}/edit', controller : 'MediaController.edit', templateUrl : 'edit_location.html'})

                .state('user',      { url : '/user',      controller : 'UserController.index',    templateUrl : 'users.html', parent : 'admin' })
                .state('user.new',  { url : '/new',       controller : 'UserController.create',  templateUrl : 'new_user.html'})
                .state('user.show', { url : '/{id}',      controller : 'UserController.show',     templateUrl : 'user.html'})
                .state('user.edit', { url : '/{id}/edit', controller : 'UserController.edit', templateUrl : 'edit_user.html'})
            ;
        }])

        .run(['$rootScope', '$state', '$auth', function ($rootScope, $state, $auth) {
            var auth = $auth.getPayload();
            $rootScope.auth = $auth.getPayload();
            $rootScope.$on('$stateChangeStart', function (e, toState, toParams) {
                if (toParams.admin == undefined) {
                    return;
                }

                if (auth && auth.administrator) {
                    return;
                }

                $state.go('index', {}, { reload : true })
                e.preventDefault();
            })
        }])

        .controller('CustomerController.index',  ['$scope', '$window', 'Customers', CustomerController.index])
        .controller('CustomerController.show',   ['$scope', '$stateParams', 'Customers', CustomerController.show])
        .controller('CustomerController.edit',   ['$scope', '$state', '$stateParams', 'Customers', 'Locales', CustomerController.edit])
        .controller('CustomerController.brand',  ['$scope', '$http', CustomerController.brand])
        .controller('CustomerController.create', ['$scope', '$state', '$stateParams', 'Customers', 'Locales', CustomerController.create])

        .controller('UserController.create', ['$scope', '$state', 'Users', UserController.create])
        .controller('UserController.index', ['$scope', '$window', 'Users', UserController.index])
        .controller('UserController.edit', ['$scope', '$state', '$stateParams', '$window', 'Users', 'Customers', 'UserCustomers', UserController.edit])

    ;
})(angular);
