;(function (angular) {
    'use strict';

    angular.module('CamPlan', [
            'CamPlan.admin', 'CamPlan.filters', 'CamPlan.config',
            'ngResource', 'ui.select', 'ngSanitize', 'ngRaven',
            'ui.router', 'ui.bootstrap',
            'satellizer', 'duScroll', 'gettext', 'darthwade.loading'])

        .config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
            $stateProvider
                .state('app', { abstract : true, templateUrl : 'app.html', controller : 'AppController' })

                .state('login',       { url : '/login',     templateUrl : 'login.html', controller : 'AuthController.login' })
                .state('logout',      { url : '/logout',   controller : 'AuthController.logout', parent : 'secure' })
                .state('not-found',   { url : '/not-found', templateUrl : 'not-found.html' })
                .state('inactive',    { url : '/inactive',  templateUrl : 'inactive.html' })
                .state('not-allowed', { url : '/not-allowed',  templateUrl : 'not-allowed.html' })

                .state('profile', { url : '/profile', controller : 'ProfileController', templateUrl : 'profile.html', parent : 'secure' })

                .state('index',  { url : '/?date', controller : 'OverviewController', templateUrl : 'index.html', parent : 'secure' })

                .state('yearview',  { url : '/yearview', controller : 'OverviewController', templateUrl : 'yearview.html', parent : 'secure' })
                .state('dayview',  { url : '/dayview', controller : 'OverviewController', templateUrl : 'dayview.html', parent : 'secure' })

                .state('location',      { url : '/location',  controller : 'LocationController.index', templateUrl : 'locations.html', parent : 'secure' })
                .state('location.new',  { url : '/new/{id}',  controller : 'LocationController.create',  templateUrl : 'new_location.html'})
                .state('location.show', { url : '/{id}',      controller : 'LocationController.show',     templateUrl : 'location.html'})
                .state('location.edit', { url : '/{id}/edit', controller : 'LocationController.edit', templateUrl : 'edit_location.html'})

                .state('user_customer', { url : '/user_customer', controller : 'UserCustomerController.index', templateUrl : 'user_customers.html', parent : 'secure' })
                .state('user_customer.show', { url : '/{id}', controller : 'UserCustomerController.show', templateUrl : 'user_customer.html' })
                .state('user_customer.edit', { url : '/{id}/edit', controller : 'UserCustomerController.edit', templateUrl : 'edit_user_customer.html' })
                .state('user_customer.create', { url : '/', controller : 'UserCustomerController.create', templateUrl : 'new_user_customer.html' })

                .state('media',       { url : '/media',      abstract: true, template: '<div class="container" ui-view></div>', parent : 'secure' })
                .state('media.list',  { url : '',            controller : 'MediaController.index',  templateUrl : 'medias.html' })
                .state('media.new',   { url : '/new',        controller : 'MediaController.create', templateUrl : 'new_media.html'})
                .state('media.show',  { url : '/{id}',       controller : 'MediaController.show',   templateUrl : 'media.html'})
                .state('media.print', { url : '/{id}/print', controller : 'MediaController.show',   templateUrl : 'media_print.html'})
                .state('media.edit',  { url : '/{id}/edit',  controller : 'MediaController.edit',   templateUrl : 'edit_media.html'})

                .state('campaign',      { url : '/campaign',  controller : 'CampaignController.index',  templateUrl : 'campaigns.html', parent : 'secure' })
                .state('campaign.new',  { url : '/new',       controller : 'CampaignController.create', templateUrl : 'new_campaign.html'})
                .state('campaign.show', { url : '/{id}?search',      controller : 'CampaignController.show',   templateUrl : 'campaign.html'})
                .state('campaign.edit', { url : '/{id}/edit', controller : 'CampaignController.edit',   templateUrl : 'edit_campaign.html'})

                .state('help', { parent : 'app', url : '/help', templateUrl : 'help.html' })
            ;

            // User required
            $stateProvider
                .state('secure', { parent : 'app', abstract : true, template : '<ui-view />', resolve : {
                    User : ['$rootScope', function ($rootScope) {
                        return $rootScope.auth
                    }]
                }})
            ;

            $urlRouterProvider.otherwise('/')
        }])

        .config(['$authProvider', function ($authProvider) {
            $authProvider.loginUrl = '/authenticate';
            $authProvider.tokenPrefix = null;
        }])

        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('UnauthorizedInterceptor')
        }])

        .config(['$locationProvider', function ($locationProvider) {
            $locationProvider.html5Mode(true);
        }])

        .config(['$tooltipProvider', function ($tooltipProvider) {
            $tooltipProvider.options({
                animation : false,
                appendToBody : true
            })
        }])

        .config(['datepickerConfig', function (datepickerConfig) {
            datepickerConfig.startingDay = 1;
            datepickerConfig.datepickerPopup = "yyyy-MM-dd"
        }])

        .run(['gettextCatalog', 'Customer', 'debug', function (gettextCatalog, Customer, debug) {
 //           gettextCatalog.debug = debug;

            if (Customer == undefined) {
                gettextCatalog.setCurrentLanguage('en');
            } else {
                gettextCatalog.setCurrentLanguage(Customer.locale);
            }
        }])

        .run(['$rootScope', '$state', '$auth', 'Customer',  function ($rootScope, $state, $auth, Customer) {
            $rootScope.$state = $state;
            $rootScope.customer = Customer;

            var ignoredStates = [
                'not-found',
                'inactive',
                'not-allowed'
            ];

            $rootScope.$on('$stateChangeStart', function (e, to, toParams, from, fromParams) {
                var auth = $auth.getPayload()

                if (ignoredStates.indexOf(to.name) >= 0) {
                    return
                }

                if (Customer == null) {
                    $state.go('not-found', {}, { reload : true })

                    e.preventDefault();

                    return;
                }

                if (auth != undefined && auth.administrator) {
                    return;
                }

                if (Customer != undefined && auth != undefined  && auth.userCustomers[Customer.id] == undefined) {
                    $auth.logout();
                    $state.go('not-allowed', {}, { reload : true })

                    e.preventDefault()
                }

                if (!Customer.active) {
                    $state.go('inactive', {}, { reload : true })

                    e.preventDefault()

                    return;
                }
            })
        }])

        .controller('AppController', ['$rootScope', '$state', 'Customer', function ($rootScope, $state, Customer) {
            $rootScope.customer = Customer
        }])

        .controller('NavbarController', ['$scope', '$auth', 'Customer', function ($scope, $auth, Customer) {
            var auth = $auth.getPayload();

            $scope.collapsed = true;
            $scope.customer = Customer;
            $scope.administrator = false;

            if (auth) {
                $scope.administrator = auth.administrator;
            }
        }])

        ///// OVERVIEW CONTROLLER STARTS HERE

        .controller('OverviewController', ['$scope', '$state', '$stateParams', '$timeout', '$q', '$modal', '$loading', '$window', '$filter', '$sce', 'Customer', 'Locations', 'Media', 'Campaigns', 'Bookings', 'CATEGORIES', 'TYPES', 'advanced_filterFilter', 'searchFilter',
                function ($scope, $state, $stateParams, $timeout, $q, $modal, $loading, $window, $filter, $sce, Customer, Locations, Media, Campaigns, Bookings, CATEGORIES, TYPES, advanced_filterFilter, searchFilter) {

            var path = $state.$current.url.source;
            var filtersTmp = {
                categories : [],
                types : [],
                locations : [],
                media : []
            };

            if ((typeof(Storage) !== 'undefined') && (typeof(JSON) !== 'undefined')) {
                if (sessionStorage.savedSearch) {
                    $scope.search = sessionStorage.savedSearch;
                }
                if (sessionStorage.filters) {
                    filtersTmp = JSON.parse(sessionStorage.filters);
                }
            }
            $scope.CATEGORIES = CATEGORIES;
            $scope.TYPES = TYPES;
            $scope.filters = filtersTmp;
            var uid;
            if (localStorage.getItem('uid')) {
                uid = localStorage.getItem('uid');
            }
            else {
                uid = null;
            }

            // Media sorted by location. Used for rendering tables.
            $scope.mediaLocation = {};

            // All media.
            var allMedia = [];
            var mediaAssoc = {};

            $scope.campaignAssoc = {};

            // All locations.
            $scope.locations = [];

            // Weeks are added to this list when clicked.
            $scope.selected = {};

            // Booked weeks. Full booking objects.
            $scope.booked = {};

            // Indexed by medium.id - formatted week. HTML to output.
            $scope.bookings = {};
            $scope.dayBookings = {};

            // Tooltip for weeks.
            $scope.tooltips = {};
            $scope.dayTooltips = {};

            // Telling which weeks to display as partials.
            $scope.partials = {};

            $scope.locationFilter = {};

            // Media sorted by the filters. Ordered by location ID.
            $scope.filteredMedia = {};

            // Due to the sligthly odd way the mediafacet is specified, the filters
            // are collected separately.
            $scope.facetMedia = [];

            $scope.weeks = [
                moment().isoWeekday(1)
            ];
                    $scope.days = [];
                    $scope.daysFormat = [];

            $scope.yearWeekEvenNumbers = [];
            $scope.yearWeekOddNumbers = [];
            // Static array for building the overview.
            $scope.weeksStatic = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
            $scope.daysStatic = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

            // Arrays to build the year view.
            $scope.evenweeks = [];
            $scope.oddweeks = [];
            $scope.yearWeekStatic = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
            // Formatted dates to avoid recalculation.
            $scope.weekFormat = [];
            // ISO week numbers of currently displayed weeks.
            $scope.isoWeekNumbers = [];

            // Precalculated cell classes.
            $scope.cellClass = {};
            $scope.dayCellClass = {};

            // Variables used for timeout.
            var updateView;
            var updateMediaTimeout;
            var updateLocationsTimeout;

            var mediaDeferred = $q.defer();

            $scope.toggle = function (list, item) {
                var index = list.indexOf(item);

                if (index >= 0) {
                    list.splice(index, 1);
                    $scope.remember();
                    $scope.updateMediaList();
                    $scope.updateLocationList();
                    return
                }

                list.push(item);
                $scope.remember();
                $scope.updateMediaList();
                $scope.updateLocationList();
            };

            $scope.reset = function () {
                $scope.filters = {
                    categories : [],
                    types : [],
                    locations : [],
                    media : []
                }
            };

            /**
             * Apply search and filtering on the media list - based on
             * $scope.mediaLocation.
             */
            $scope.updateMediaList = function() {
                var mediaLocation = angular.copy($scope.mediaLocation);
                for (var i in mediaLocation) {
                    var mediaList = mediaLocation[i];
                    if ($scope.search && $scope.search.length > 1) {
                        mediaList = searchFilter(mediaList, $scope.search);
                    }
                    $scope.filteredMedia[i] = advanced_filterFilter(mediaList, $scope.filters);
                }

                $scope.updateMediaListSearchForBooked();
            };

            /**
                *@desc Runs as an extendion to updateMediaList, it will run all booked campaign and find the matching media
                *@params $scope.booked, mediaAssoc
            */
            $scope.updateMediaListSearchForBooked = function () {
                var booked = angular.copy($scope.booked);
                // j is the media id
                for(var j in booked){
                    // Iterate through the dates.
                    for(var n in booked[j]){
                        var bookedList = booked[j][n];
                        // Apply the filter if there is a search string.
                        if ($scope.search && $scope.search.length > 1) {
                            bookedList = searchFilter(bookedList, $scope.search);
                            if (bookedList.length > 0) {
                                var mediaId = bookedList[0].media.id;
                                if (j == bookedList[0].media.id && $scope.filteredMedia[mediaAssoc[mediaId].location.id].indexOf(mediaAssoc[mediaId]) < 0){
                                    var locationId = mediaAssoc[mediaId].location.id,
                                        campaign = mediaAssoc[mediaId];
                                    if (!isItDuplicate(locationId, campaign)) {
                                        $scope.filteredMedia[mediaAssoc[mediaId].location.id].push(mediaAssoc[mediaId]);
                                    }
                                }
                            }
                        }
                    }
                }
            };

            function isItDuplicate (locationId, campaign) {
                var isDuplicate = false;
                for (var i = 0; i < $scope.filteredMedia[locationId].length; i++) {
                    if ($scope.filteredMedia[locationId][i].id === campaign.id) {
                        isDuplicate = true;
                    }
                }
                return isDuplicate;
            }

            var campaignId = null;

            $scope.onSelected = function (campaign) {
                campaignId = campaign.id;
                $scope.filteredMedia = [];
                if (campaign.id) {
                    $scope.updateMediaListSearchForCampaign(campaign)
                } else {
                    $scope.updateMediaList();
                }
            };

            $scope.updateMediaListSearchForCampaign = function (campaign) {
                var booked = angular.copy($scope.booked);
                // j is the media id
                for(var j in booked) {
                    // Iterate through the dates.
                    for (var n in booked[j]) {
                        var bookedList = booked[j][n];
                        // Apply the filter if there is a match
                        if (bookedList[0].campaign.id === campaign.id) {
                            if (bookedList.length > 0) {
                                var mediaId = bookedList[0].media.id;
                                if (!$scope.filteredMedia[mediaAssoc[mediaId].location.id]) {
                                    $scope.filteredMedia[mediaAssoc[mediaId].location.id] = [];
                                }
                                if (j == bookedList[0].media.id && $scope.filteredMedia[mediaAssoc[mediaId].location.id].indexOf(mediaAssoc[mediaId]) < 0){
                                    $scope.filteredMedia[mediaAssoc[mediaId].location.id].push(mediaAssoc[mediaId]);
                                }
                            }
                        }
                    }
                }
            };

            $scope.refreshData = function(skipBooking, week, who) {
                if (skipBooking) {
                    bookings(Customer.id, moment($scope.start).format('YYYY-MM-DD'), moment($scope.end).format('YYYY-MM-DD'), 'refresh');
                }
                Campaigns.query({ customer : Customer.id, active: true }, function (campaigns) {
                    var campaignsSelect = campaigns;
                    campaignsSelect.unshift({id: null, title: 'Choose campaign'});
                    $scope.campaignsSelect = campaignsSelect;
                    $scope.campaigns = campaigns;

                    sessionStorage.setItem('campaigns_' + Customer.id, JSON.stringify(campaigns));
                });
            };

            /**
             * Apply search and filtering on the media list - based on
             * $scope.mediaLocation.
             */
            // TODO
            $scope.updateLocationList = function() {
                var locations = angular.copy($scope.locations);
                if ($scope.search && $scope.search.length > 1) {
                    locations = searchFilter(locations, $scope.search);
                }

                if (!$scope.filters.locations || $scope.filters.locations.length < 1) {
                    $scope.topLocations = locations;
                    return true;
                }

                var filteredLocations = [];

                for (var i in locations) {
                    if ($scope.filters.locations.indexOf(locations[i].id) >= 0) {
                        filteredLocations.push(locations[i]);
                    }
                }

                $scope.topLocations = filteredLocations;
            };

            // Save selected filters.
            $scope.remember = function () {
                if ((typeof(JSON) === 'undefined') || (typeof(Storage) === 'undefined')) {
                    return true;
                }
                sessionStorage.setItem('filters', JSON.stringify($scope.filters));
            };

            $scope.saveSearch = function() {
                $timeout.cancel(updateMediaTimeout);

                updateMediaTimeout = $timeout(function(){
                    $scope.updateMediaList();
                }, 400);
                sessionStorage.setItem('savedSearch', $scope.search);
            }

            $scope.checkFilter = function (list, value) {
                return list.indexOf(value) >= 0;
            }

            $scope.filterLocation = function (location) {
                if ($scope.filters.locations.length) {
                    if ($scope.filters.locations.indexOf(location.id) < 0) {
                        return false;
                    }
                }

                return true;
            };

            $scope.filter = function (medium, index) {


                if ($scope.filters.categories.length) {
                    if ($scope.filters.categories.indexOf(medium.category) < 0) {
                        return false;
                    }
                }

                if ($scope.filters.types.length) {
                    if ($scope.filters.types.indexOf(medium.type) < 0) {
                        return false;
                    }
                }

                if ($scope.filters.locations.length) {
                    if ($scope.filters.locations.indexOf(medium.location.id) < 0) {
                        return false;
                    }
                }

                if ($scope.filters.media.length) {
                    if ($scope.filters.media.indexOf(medium.title) < 0) {
                        return false;
                    }
                }

                return true;
            };

            var setLastDate = function(lastDate) {
                sessionStorage.setItem('lastDate', moment(lastDate).format('YYYY-MM-DD'));
            };

            // Jump to a date from the year view.
            $scope.jumpToDate = function($event) {
                if ($event.srcElement) {
                    var date = $event.srcElement.attributes.getNamedItem('data-date');
                }
                else if ($event.originalTarget) {
                    var date = $event.originalTarget.attributes.getNamedItem('data-date');
                }

                if (!date) {
                    return true;
                }
                setLastDate(date.value);
                $state.go('index');
            };
            $scope.help = function () {
                $modal.open({
                    templateUrl : '_index_help.html'
                })
            };

            var campaignsRaw = sessionStorage.getItem('campaigns_' + Customer.id);
            if (campaignsRaw) {
                var campaigns = JSON.parse(campaignsRaw),
                    campaignsSelect = campaigns;
                campaignsSelect.unshift({id: null, title: 'Choose campaign'});
                $scope.campaignsSelect = campaignsSelect;
                $scope.campaigns = campaigns;
            }
            else {
                Campaigns.query({ customer : Customer.id, active: true }, function (campaigns) {
                    var campaignsSelect = campaigns;
                    campaignsSelect.unshift({id: null, title: 'Choose campaign'});
                    $scope.campaignsSelect = campaignsSelect;
                    $scope.campaigns = campaigns ;
                    sessionStorage.setItem('campaigns_' + Customer.id, JSON.stringify(campaigns));
                });
            }

            // Tooltip callback on images.
            $scope.popupImage = function(path) {
                if (!path) {
                    return '';
                }
                return '<img class="booking-popup-img" src="' + path + '" />';
            };

            // Fetch media entries from DB or sessionstorage.
            function media(customerId) {
                var mediaRaw = sessionStorage.getItem('media_' + customerId);
                if (mediaRaw) {
                    var media = JSON.parse(mediaRaw);
                    allMedia = media;
                    mediaDeferred.resolve();
                    var locationsTmp = [];
                    // TODO

                    for (var i = 0; i < media.length; i++) {
                        var medium = media[i];
                        mediaAssoc[medium.id] = medium;
                        if ($scope.mediaLocation[medium.location.id] == undefined) {
                            $scope.mediaLocation[medium.location.id] = [];
                            locationsTmp.push(medium.location);
                        }
                        $scope.mediaLocation[medium.location.id].push(medium);
                        if ($scope.facetMedia.indexOf(medium.title) == -1) {
                            $scope.facetMedia.push(medium.title);
                        }
                    }
                    $scope.locations = locationsTmp;
                    $scope.topLocations = angular.copy(locationsTmp);
                    $scope.updateMediaList();
                    return mediaDeferred.promise;
                }
                // No cache available. Fetch from DB.
                Media.query({ customer : customerId }, function (media) {
                    allMedia = media;
                    mediaDeferred.resolve();
                    var locationsTmp = [];
                    for (var i = 0; i < media.length; i++) {
                        var medium = media[i];
                        mediaAssoc[medium.id] = medium;
                        if ($scope.mediaLocation[medium.location.id] == undefined) {
                            $scope.mediaLocation[medium.location.id] = [];
                            locationsTmp.push(medium.location);
                        }
                        $scope.mediaLocation[medium.location.id].push(medium);
                        if ($scope.facetMedia.indexOf(medium.title) == -1) {
                            $scope.facetMedia.push(medium.title);
                        }
                    }
                    $scope.locations = locationsTmp;
                    $scope.topLocations = angular.copy(locationsTmp);
                    $scope.updateMediaList();

                    sessionStorage.setItem('media_' + customerId, JSON.stringify(media));
                });
                return mediaDeferred.promise;
            }
            // Return bookings on a specific week.
            $scope.booking = function (medium, week, format) {
                var bookings = $scope.bookings[medium.id];

                if (bookings == undefined) {
                    return bookings;
                }

                if (!format) {
                    format = week.format('YYYY-MM-DD');
                }

                return bookings[format];
            }

            // Submit bookings.
            $scope.book = function (confirm, dayView) {
                if (!$filter('can_book')(Customer)) {
                    return;
                }

                var selected = 0;

                angular.forEach($scope.selected, function (v) {
                    selected += v.length;
                });


                if (!selected) {
                    return;
                }

                sessionStorage.removeItem('bookings_' + Customer.id);

                if ($scope.campaign) {
                    $loading.start('booking');
                    book($scope.campaign, confirm, dayView);

                    return;
                }

                var title = $window.prompt('Enter name of the campaign to create.');
                if (title && !title.length) {
                    return;
                }

                $loading.start('booking');
                Campaigns.save({ title : title, customer : Customer.id }, function (campaign) {
                    $loading.finish('booking');
                    $scope.campaigns.push(campaign);
                    $scope.campaign = campaign.id;
                    var campaignsSelect = campaigns;
                    campaignsSelect.unshift({id: null, title: 'Vælg en kampagne'});
                    $scope.campaignsSelect = campaignsSelect;
                    sessionStorage.setItem('campaigns_' + Customer.id, JSON.stringify($scope.campaigns));

                    book(campaign.id, confirm, dayView);
                }, function () {
                    $loading.finish('booking');
                    $window.alert('Campaign could not be created.')
                });
            };

            /**
             * Get booking status for a week on a medium.
             *
             * 0: Free to book.
             * 1: Can be booked, but will be secondary reservist.
             * 2: Booked.
             */
            $scope.isBooked = function (medium, week, format) {
                if ((week == undefined)) {
                    return 0;
                }
                if (!format) {
                    format = moment(week).format('YYYY-MM-DD');
                }
                if ($scope.booked[medium.id][format] == undefined) {
                    return 0;
                }

                if (($scope.booked[medium.id][format].length < 1)) {
                    return 0;
                }

                if ($scope.booked[medium.id][format][0].confirmed == 1) {
                    return 2;
                }

                return 1;
            };

            $scope.isSelected = function (medium, week) {
                var selected = $scope.selected[medium.id];
                if (selected == undefined) {
                    return false;
                }
                return selected.indexOf(week) >= 0;
            };

            // When doubleclicking a cell.
            $scope.instantBook = function(media, week, dayView) {
                $scope.select(media, week);
                $scope.book(false, dayView);
            };

            // Select a cell.
            $scope.select = function(media, week) {
                if (!$filter('can_book')(Customer)) {
                    return;
                }

                if ($scope.isBooked(media, week) > 1) {
                    return;
                }

                if ($scope.isSelected(media, week)) {
                    $scope.selected[media.id].splice($scope.selected[media.id].indexOf(week), 1);

                    return;
                }

                $scope.selected[media.id].push(week);
            };


            // Return the rendered booking labels as trusted HTML.
            $scope.bookingLabels = function(mediumId, formattedWeek) {
                if (campaignId) {
                    if ($scope.bookings[mediumId][formattedWeek]) {
                        var checkCampaignId = $scope.bookings[mediumId][formattedWeek].split('"');
                        checkCampaignId = checkCampaignId[1].split('/');
                        if (checkCampaignId[2] == campaignId) {
                            return $sce.trustAsHtml($scope.bookings[mediumId][formattedWeek]);
                        } else {
                            return '';
                        }
                    }
                } else {
                    return $sce.trustAsHtml($scope.bookings[mediumId][formattedWeek]);
                }
            };

                    // Return the rendered booking labels as trusted HTML.
                    $scope.dayBookingLabels = function(mediumId, formattedWeek) {
                        if (campaignId) {
                            if ($scope.dayBookings[mediumId][formattedWeek]) {
                                var checkCampaignId = $scope.dayBookings[mediumId][formattedWeek].split('"');
                                checkCampaignId = checkCampaignId[1].split('/');
                                if (checkCampaignId[2] == campaignId) {
                                    return $sce.trustAsHtml($scope.dayBookings[mediumId][formattedWeek]);
                                } else {
                                    return '';
                                }
                            }
                        } else {
                            return $sce.trustAsHtml($scope.dayBookings[mediumId][formattedWeek]);
                        }
                    };

            /**
             * Submit a booking.
             *
             * This is only executed when bookings are submitted, so we allow
             * the angular.forEach.
             */
            function book(campaign, confirm, dayView) {
                // Make sure confirm is boolean.
                confirm = !!confirm;

                var newBookings = [];
                var latestEnd = moment("20000101", "YYYYMMDD");
                var latestCardinality = 0;
                angular.forEach($scope.selected, function (weeks, media) {
                    angular.forEach(weeks, function (week) {
                        var weekStart = moment(week).format('YYYY-MM-DD');
                        var cardinality = 0;
                        var isConfirmed = confirm;
                        if ($scope.bookings[media][weekStart] && ($scope.bookings[media][weekStart].length > 0)) {
                            cardinality = 1;
                            isConfirmed = false;
                        }

                        var lastBooking = newBookings[newBookings.length - 1];
                        if (cardinality == 0 && moment(week).diff(latestEnd, 'days') == 1 && cardinality == latestCardinality && lastBooking.media == media && typeof dayView === 'undefined') {
                            lastBooking.period.end = moment(week).isoWeekday(7).format('YYYY-MM-DD');
                        } else {

                            if (dayView === true && lastBooking) {
                                if (
                                  weekStart == moment(lastBooking.period.end).add(1, 'days').format('YYYY-MM-DD')
                                  && lastBooking.media == media
                                ) {
                                    lastBooking.period.end = weekStart;
                                    return false;
                                }
                            }
                            var params = {
                                campaign : campaign,
                                media : media,
                                confirmed: isConfirmed,
                                cardinality: cardinality,
                                user: uid,
                                period : {
                                    start : weekStart,
                                    end : dayView === true ? weekStart : moment(week).isoWeekday(7).format('YYYY-MM-DD'),
                                }
                            };
                            newBookings.push(params);
                        }

                        latestEnd = moment(week).isoWeekday(7);
                        latestCardinality = cardinality;
                    });
                });
                bookRecursive(newBookings);
            }

            function bookRecursive(newBookings) {
                var deferred = $q.defer();
                var booking = newBookings.shift();
                if (!booking) {
                    $loading.finish('booking');
                    bookings(Customer.id, moment($scope.start).format('YYYY-MM-DD'), moment($scope.end).format('YYYY-MM-DD'));
                    deferred.reject(false);
                    return true;
                }

                Bookings.create(booking, function(data) {
                    bookRecursive(newBookings);
                    deferred.resolve(data);
                });
                return deferred.promise;
            }
            $scope.counter = 0;

            /**
             * Create the bookings- and partials objects.
             */
            function bookings(customerId, startDate, endDate, reset) {
                for(var i in allMedia) {
                    var medium =  allMedia[i];
                    $scope.booked[medium.id] = {};
                    $scope.selected[medium.id] = [];
                    $scope.bookings[medium.id] = {};
                    $scope.dayBookings[medium.id] = {};

                    $scope.cellClass[medium.id] = {};
                    $scope.dayCellClass[medium.id] = {};
                    $scope.tooltips[medium.id] = {};
                    $scope.dayTooltips[medium.id] = {};
                }
                $loading.start('booking');
                Bookings.query({ customer : customerId, start: startDate, end: endDate }, function (bookings) {
                     for (var i = 0; i < bookings.length; i++) {
                        var booking = {
                            id: bookings[i].id,
                            user: bookings[i].u,
                            cardinality: bookings[i].ca,
                            period: bookings[i].p,
                            confirmed: bookings[i].co,
                            media: {'id': bookings[i].m},
                            campaign: bookings[i].cam
                        };

                        var start = moment(booking.period.start);
                        var end = moment(booking.period.end);

                        while (start.unix() <= end.unix()) {
                            start = setBooking(booking, start, end);
                        }
                    }

                    $scope.updateMediaList();

                    $loading.finish('booking');
                });
            }

            function formatTooltip(booking) {
                var label = booking.campaign.title;

                if (!booking.user || !booking.user.n) {
                    return label;
                }
                label += ' (' + booking.user.n;

                if (booking.user.p) {
                    label += ' ' + booking.user.p;
                }
                return label + ')';
            }

            // Process booking for $scope variables.
            function setBooking (booking, start, end) {
                var format = angular.copy(start).isoWeekday(1).format('YYYY-MM-DD');

                var duration = end.diff(start, 'days');
                var nextWeek = angular.copy(start).add(1, 'week').isoWeekday(1);
                var startWeekDay = start.isoWeekday();

                if (!$scope.booked[booking.media.id][format]) {
                    $scope.booked[booking.media.id][format] = [];
                }
                $scope.booked[booking.media.id][format].push(booking);

                if (!$scope.cellClass[booking.media.id][format]) {
                    var cellClass = booking.campaign.color;
                    if (!cellClass) {
                        cellClass = booking.confirmed ? 'bg-danger' : 'bg-warning'
                    }
                    $scope.cellClass[booking.media.id][format] = cellClass;
                } else {
                    $scope.cellClass[booking.media.id][format] = 'bg-split-booking';
                }


                // Render tooltips and links to output on the week.
                var renderedBooking = '<a href="/campaign/' + booking.campaign.id + '">' + booking.campaign.title.substring(0,6) + '..</a>';
                if (!$scope.bookings[booking.media.id][format]) {
                    $scope.bookings[booking.media.id][format] = renderedBooking;
                    $scope.tooltips[booking.media.id][format] = formatTooltip(booking);
                }
                else if ($scope.bookings[booking.media.id][format].indexOf(booking.campaign.title) < 0 &&
                  booking.cardinality == 1
                ) {
                    $scope.bookings[booking.media.id][format] += ' ' + renderedBooking;
                    $scope.tooltips[booking.media.id][format] += ' ' + formatTooltip(booking);
                }
                else if ($scope.bookings[booking.media.id][format].indexOf(booking.campaign.title)) {
                    $scope.bookings[booking.media.id][format] = renderedBooking + ' ' + $scope.bookings[booking.media.id][format];
                    $scope.tooltips[booking.media.id][format] = formatTooltip(booking) + ' ' + $scope.tooltips[booking.media.id][format];
                }

                for (var i = 0; i <= duration; i++) {
                    var day = i !== 0 ? start.add(1, 'days') : start;
                    var dayFormat = day.format('YYYY-MM-DD');

                    if (!$scope.dayCellClass[booking.media.id][dayFormat]) {
                        var cellClass = booking.campaign.color;
                        if (!cellClass) {
                            cellClass = booking.confirmed ? 'bg-danger' : 'bg-warning'
                        }
                        $scope.dayCellClass[booking.media.id][dayFormat] = cellClass;
                    } else {
                        //scope.dayCellClass[booking.media.id][dayFormat] = 'bg-split-booking';
                    }


                    // Render tooltips and links to output on the week.
                    var renderedBooking = '<a href="/campaign/' + booking.campaign.id + '" data-title="' + booking.campaign.title + '">' + booking.campaign.title.substring(0,6) + '..</a>';
                    if (!$scope.dayBookings[booking.media.id][dayFormat]) {
                        $scope.dayBookings[booking.media.id][dayFormat] = renderedBooking;
                        $scope.dayTooltips[booking.media.id][dayFormat] = formatTooltip(booking);
                    } else if ($scope.dayBookings[booking.media.id][dayFormat].indexOf(booking.campaign.title) === -1) {
                        $scope.dayBookings[booking.media.id][dayFormat] += ' ' + renderedBooking;
                        $scope.dayTooltips[booking.media.id][dayFormat] += ' ' + formatTooltip(booking);
                    }
                }

                // Register a week as partial.
                if (((startWeekDay > 1) || (duration < 5))) {
                    $scope.cellClass[booking.media.id][format] += ' partial-booked';

                    /*if (((startWeekDay + duration) > 7) && ((duration + startWeekDay) < 15)) {
                        var nextWeekFormat = nextWeek.format('YYYY-MM-DD');
                        $scope.cellClass[booking.media.id][nextWeekFormat] += ' partial-booked';
                    }*/
                }
                return nextWeek;
            }

            // Setup the tables after date selection.
            function recalculate (date) {
                $scope.start = date;
                $scope.end = moment(date).add(12, 'week');
                $scope.week = date.isoWeek();
                $scope.year = date.year();

                angular.forEach($scope.selected, function(o, media) {
                    $scope.selected[media] = [];
                });

                $scope.weeks = [
                    date
                ];
                $scope.days = [
                  date
                ];
                $scope.daysFormat = [
                    moment(date).format('YYYY-MM-DD')
                ];
                $scope.weekFormat = [
                    moment(date).format('YYYY-MM-DD')
                ];
                $scope.isoWeekNumbers = [
                    date.isoWeek()
                ];

                angular.forEach([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (index) {
                    var newDate = moment(date).add(index, 'week');
                    var day = moment(date).add(index, 'day');
                    $scope.days.push(day);
                    $scope.weeks.push(newDate);
                    $scope.daysFormat.push(day.format('YYYY-MM-DD'));
                    $scope.weekFormat.push(newDate.format('YYYY-MM-DD'));
                    $scope.isoWeekNumbers.push(newDate.isoWeek());
                });

                bookings(Customer.id, moment($scope.start).format('YYYY-MM-DD'), moment($scope.end).format('YYYY-MM-DD'), '1');
            }

            // Setup the tables after date selection.
            function recalculateYear (date) {
                $scope.start = date;
                $scope.end = moment(date).isoWeekday(1).add(1,'year');
                $scope.week = date.isoWeek();
                $scope.year = date.year();
                $scope.evenweeks = [];
                $scope.oddweeks = [];
                $scope.endweek = moment(date).isoWeekday(1).add(1,'year').isoWeek();
                $scope.endyear = moment(date).isoWeekday(1).add(1,'year').year();

                var enddate = moment().year($scope.endyear).isoWeek($scope.endweek).isoWeekday(1);

                var index = 0;
                $scope.yearWeekEvenNumbers = [];
                $scope.yearWeekOddNumbers = [];
                $scope.yearWeekEvenFormat = [];
                $scope.yearWeekOddFormat = [];

                while (moment(date).add(index, 'week') < enddate) {
                    var week = moment(date).add(index, 'week');
                    if (index % 2 == 0) {
                        $scope.evenweeks.push(week);
                        $scope.yearWeekEvenNumbers.push(week.isoWeek());
                        $scope.yearWeekEvenFormat.push(moment(week).format('YYYY-MM-DD'));
                    }
                    else {
                        $scope.oddweeks.push(week);
                        $scope.yearWeekOddNumbers.push(week.isoWeek());
                        $scope.yearWeekOddFormat.push(moment(week).format('YYYY-MM-DD'));
                    }
                    index++;
                }
                if (moment($scope.evenweeks.slice(-1)[0]).isoWeek() > moment($scope.oddweeks.slice(-1)[0]).isoWeek()) {
                    $scope.endweek = moment($scope.evenweeks.slice(-1)[0]).isoWeek();
                    $scope.endyear = moment($scope.evenweeks.slice(-1)[0]).year();
                } else {
                    $scope.endweek = moment($scope.oddweeks.slice(-1)[0]).isoWeek();
                    $scope.endyear = moment($scope.oddweeks.slice(-1)[0]).year();
                }
                bookings(Customer.id, moment($scope.start).format('YYYY-MM-DD'), moment(enddate).format('YYYY-MM-DD'), 'year');
            }

            $scope.next = function (week, num) {
                var num = typeof num !== 'undefined' ? num : 5;
                recalculate(moment($scope.start).add(num, 'week'));
                sessionStorage.setItem('lastDate', moment($scope.start).format('YYYY-MM-DD'));
            };

            $scope.previous = function (week, num) {
                var num = typeof num !== 'undefined' ? num : 5;
                recalculate(moment($scope.start).subtract(num, 'week'));
                sessionStorage.setItem('lastDate', moment($scope.start).format('YYYY-MM-DD'));
            };

            $scope.goToWeek = function (keycode) {
                var date = moment().year($scope.year).isoWeek($scope.week).isoWeekday(1);
                if (keycode.code === 'Enter') {
                    recalculate(date);
                }
            };

            $scope.nextOne = function () {
                recalculateYear(moment($scope.start).add(1, 'week'));
            };

            $scope.previousOne = function () {
                recalculateYear(moment($scope.start).subtract(1, 'week'));
            };

            $scope.nextEnd = function () {
                $scope.endweek = moment().year($scope.endyear).isoWeek($scope.endweek).add(1, 'week').isoWeek();
                recalculateYear(moment($scope.start));
            };

            $scope.previousEnd = function () {
                $scope.endweek = moment().year($scope.endyear).isoWeek($scope.endweek).subtract(1, 'week').isoWeek();
                recalculateYear(moment($scope.start));
            };

            $scope.resetWeek = function () {
                var now = moment().isoWeekday(1);
                recalculate(now);
                sessionStorage.setItem('lastDate', now.format('YYYY-MM-DD'));
            };

            $scope.weekYear = function () {
                if (!$scope.year) {
                    return;
                }

                if (!$scope.week) {
                    return;
                }

                if ($scope.week > 53) {
                    return;
                }

                var date = moment().year($scope.year).isoWeek($scope.week).isoWeekday(1);
                recalculateYear(date, 'week');
                setLastDate($scope.start);
            };

            $scope.weekYearWithTimeout = function() {
                $timeout.cancel(updateView);
                updateView = $timeout(function(){ $scope.weekYear(); }, 400);
            };

            // This is a tad dodgy, but it makes the markup appear before it
            // starts rendering the tables.
            var mediaPromise = media(Customer.id);

            mediaPromise.then(function(){
                if ($state.$current.url.source == '/yearview') {
                    if (sessionStorage.getItem('lastDate')) {
                        recalculateYear(moment(sessionStorage.getItem('lastDate')).isoWeekday(1));
                    } else if ($stateParams.date) {
                        sessionStorage.setItem('lastDate', $stateParams.date);
                        recalculateYear(moment($stateParams.date).isoWeekday(1));
                    } else {
                        recalculateYear(moment().isoWeekday(1));
                    }
                } else if ($state.$current.url.source == '/dayview') {
                    if (sessionStorage.getItem('lastDate')) {
                        recalculate(moment(sessionStorage.getItem('lastDate')).isoWeekday(1));
                    } else if ($stateParams.date) {
                        sessionStorage.setItem('lastDate', $stateParams.date);
                        recalculate(moment($stateParams.date).isoWeekday(1));
                    } else {
                        recalculate(moment().isoWeekday(1));
                    }                } else {
                    if (sessionStorage.getItem('lastDate')) {
                        recalculate(moment(sessionStorage.getItem('lastDate')).isoWeekday(1));
                    } else if ($stateParams.date) {
                        sessionStorage.setItem('lastDate', $stateParams.date);
                        recalculate(moment($stateParams.date).isoWeekday(1));
                    } else {
                        recalculate(moment().isoWeekday(1));
                    }
                }
            });

        }])


        //// OVERVIEW CONTROLLER ENDS HERE

        .controller('ProfileController', ['$scope', '$auth', 'Users', function ($scope, $auth, Users) {
            $scope.updated = false;
            $scope.errors = {};
            $scope.user = Users.get({ id : $auth.getPayload().id });
            $scope.password = {
                first : null,
                second : null
            };

            $scope.submit = function () {
                var user = $scope.user
                user.user = user.id
                user.password = $scope.password

                Users.save(user, function (user) {
                    $scope.user = user
                    $scope.updated = true
                    $scope.errros = {}
                }, function (response) {
                    $scope.errors = response.data;
                })
            };
        }])

        .controller('AuthController.login', ['$scope', '$state', '$auth', 'Customer', 'Users', AuthController.login])
        .controller('AuthController.logout', ['$state', '$auth', AuthController.logout])

        .controller('UserCustomerController.index', ['$scope', '$window', 'UserCustomers', 'Customer', UserCustomerController.index])
        .controller('UserCustomerController.show', ['$scope', '$stateParams', 'UserCustomers', 'Customer', UserCustomerController.show])
        .controller('UserCustomerController.edit', ['$scope', '$stateParams', '$state', 'UserCustomers', 'Locations', 'Customer', UserCustomerController.edit])
        .controller('UserCustomerController.create', ['$scope', '$stateParams', 'UserCustomers', 'Customer', UserCustomerController.show])

        .controller('LocationController.index',  ['$scope', '$state', '$window', 'Locations', 'Customer', LocationController.index])
        .controller('LocationController.show',   ['$scope', '$stateParams', 'Locations', 'Media', LocationController.show])
        .controller('LocationController.create', ['$scope', '$state', '$stateParams', 'Locations', 'Media', 'Countries', 'Customer', LocationController.create])
        .controller('LocationController.edit',   ['$scope', '$state', '$stateParams', 'Locations', 'Countries', 'Customer', LocationController.edit])

        .controller('MediaController.index', ['$scope', '$window', '$modal', 'Media', 'Customer', MediaController.index])
        .controller('MediaController.show', ['$scope', '$stateParams', '$window', '$compile', 'Media', '$http', MediaController.show])
        .controller('MediaController.edit', ['$scope', '$stateParams', '$http', '$window', 'Media', MediaController.edit])
        .controller('MediaController.create', ['$scope', 'Media', MediaController.create])
        .controller('MediaController.form', ['$scope', '$state', 'Locations', 'Media', 'Customer', 'TYPES', 'CATEGORIES', MediaController.form])
        .controller('MediaController.duplicateModal', ['$scope', '$window', 'Locations', 'Media', 'Customer', 'selectedMedia', MediaController.duplicateModal])

        .controller('CampaignController.index', ['$scope', '$window', 'Campaigns', 'Customer', CampaignController.index])
        .controller('CampaignController.show', ['$scope', '$stateParams', '$window', '$http', 'Campaigns', 'Bookings', 'Media', 'Customer', CampaignController.show])
        .controller('CampaignController.edit', ['$scope', '$state', '$http', '$window', '$stateParams', 'Campaigns', CampaignController.edit])
        .controller('CampaignController.create', ['$scope', '$state', 'Campaigns', 'Customer', CampaignController.create])


        .decorator('$resource', ['$delegate', function ($delegate) {
            return function (url, paramDefaults, actions, options) {
                var actions = actions || {}

                actions = angular.extend(actions, {
                    update : { method : 'patch', isArray: false },
                    create : { method : 'post', isArray: false }
                })

                return $delegate.apply(this, [url, paramDefaults, actions, options]);
            }
        }])

        .factory('Media', ['$resource', function ($resource) {
            return $resource('/api/media/:id', { id : '@id' }, {
                primaryImage : {
                    url : '/api/media/:id/image/:image',
                    method : 'PATCH',
                    params : {
                        id : '@id',
                        image : '@image'
                    }
                },
                deleteImage : {
                    url : '/api/media/:id/image/:image',
                    method : 'DELETE',
                    params : {
                        id : '@id',
                        image : '@image'
                    }
                },
                deleteFile : {
                    url : '/api/media/:id/file/:file',
                    method : 'DELETE',
                    params : {
                        id : '@id',
                        image : '@file'
                    }
                }
            })
        }])

        .factory('Customers', ['$resource', function ($resource) {
            return $resource('/api/customer/:id', { id : '@id' });
        }])

        .factory('Campaigns', ['$resource', function ($resource) {
            return $resource('/api/campaign/:id', { id : '@id' }, {
              deleteImage : {
                url : '/api/campaign/:id/image/:image',
                method : 'DELETE',
                params : {
                  id : '@id',
                  image : '@image'
                }
              },
              deleteFile : {
                url : '/api/campaign/:id/file/:file',
                method : 'DELETE',
                params : {
                  id : '@id',
                  image : '@file'
                }
              }
            });
        }])

        .factory('UserCustomers', ['$resource', function ($resource) {
            return $resource('/api/user_customer/:id', { id : '@id' }, {
                invite : {
                    method : 'POST',
                    url : '/api/user_customer/invite',
                    isArray : false
                }
            });
        }])

        .factory('Locations', ['$resource', function ($resource) {
            return $resource('/api/location/:id', { id : '@id' });
        }])

        .factory('Bookings', ['$resource', function ($resource) {
            return $resource('/api/booking/:id', { id : '@id' });
        }])

        .factory('Users', ['$resource', '$auth', function ($resource, $auth) {
            return $resource('/api/user/:id', { id : '@id' });
        }])

        .factory('UnauthorizedInterceptor', ['$q', '$injector', function ($q, $injector) {
            return {
                responseError : function (rejection) {
                    var $state = $injector.get('$state');
                    var $auth = $injector.get('$auth');

                    if (rejection.status != 401) {
                        return $q.reject(rejection);
                    }

                    if ($state.current.name == 'login') {
                        return $q.reject(rejection);
                    }

                    $auth.logout();
                    $state.go('login');

                    return $q.reject(rejection);
                }
            }
        }])

        .directive('yesNoLabel', [function () {
            return {
                restrict : 'E',
                replace : true,
                scope : {
                    yesNo : '='
                }
            }
        }])

        .directive('themeSelect', [function () {
            return {
                restrict : 'E',
                replace : true,
                scope : {
                    theme : '='
                },
                controller : ['$scope', 'Themes', function ($scope, Themes) {
                    $scope.themes = Themes
                }],
                template : '<select class="form-control" ng-model="theme" ng-options="name as title for (name, title) in themes"></select>'
            }
        }])

        .directive('localeSelect', [function () {
            return {
                restrict : 'E',
                replace : true,
                scope : {
                    theme : '='
                },
                controller : ['$scope', 'Themes', function ($scope, Themes) {
                    $scope.themes = Themes
                }],
                template : '<select class="form-control" ng-model="theme" ng-options="name as title for (name, title) in themes"></select>'
            }
        }])

        .directive('countrySelect', [function () {
            return {
                restrict : 'E',
                replace : true,
                scope : {
                    theme : '='
                },
                controller : ['$scope', 'Themes', function ($scope, Themes) {
                    $scope.themes = Themes
                }],
                template : '<select class="form-control" ng-model="theme" ng-options="name as title for (name, title) in themes"></select>'
            }
        }])

        .constant('Themes', {
            'default'  : 'Default',
            'blueish'  : 'Blueish',
            'orangish' : 'Orangish',
            'bookish'  : 'Bookish'
        })

        .constant('CATEGORIES', {
            'tv'            : 'Tv',
            'online'        : 'Online',
            'digital'       : 'Digital',
            'print'         : 'Print',
            'radio'         : 'Radio',
            'cinema'        : 'Cinema',
            'outdoor'       : 'Outdoor',
            'instore'       : 'Instore',
            'direct_mail'   : 'Direct mail',
            'sponsor'       : 'Sponsor',
            'pr'            : 'PR',
            'event'         : 'Event',
            'other'         : 'Other'
        })

        .constant('TYPES', {
            'adwords'                   : 'Adwords',
            'article'                   : 'Article',
            'banner'                    : 'Banner',
            'brochure'                  : 'Brochure',
            'content'                   : 'Content',
            'digital_banner'            : 'Digital banner',
            'digital_magazine'          : 'Digital magazine',
            'flyer'                     : 'Flyer',
            'folie'                     : 'Folie',
            'inserts'                   : 'Inserts',
            'sound_spot'                : 'Sound-spot',
            'magazine'                  : 'Magazine',
            'online_ad'                 : 'Online-ad',
            'pos_material'              : 'POS material',
            'print_ad'                  : 'Print-ad',
            'poster'                    : 'Poster',
            'rubberframe'               : 'Rubberframe',
            'rubberframe_light_text'    : 'Rubberframe light text',
            'tv_spot'                   : 'Tv-spot',
            'other'                     : 'Other'
        })

        .constant('Countries', {
            DK : 'Denmark',
            FI : 'Finland',
            DE : 'Germany',
            IS : 'Iceland',
            NO : 'Norway',
            SE : 'Sweden',
            GB : 'United Kingdom',
            US : 'United States'
        })

        .constant('Locales', {
            da : 'Danish',
            de : 'German',
            en : 'English (British)'
        })
    ;

})(angular);

function User() {
    this.id;
    this.email;
    this.password;
    this.fullName;
    this.phoneNo;
    this.administrator;
    this.roles;
    this.active;
}

function Customer() {
    this.id;
    this.title;
    this.name;
    this.brand;
    this.active    = true;
    this.protected = false;
    this.locale    = 'da';
    this.theme     = 'default';
}

function Location() {
    this.id;
    this.title;
    this.streetName;
    this.houseNo;
    this.postalCode;
    this.city;
    this.country = 'DK';
    this.contacts = [];
}

function Contact() {
    this.id;
    this.name;
    this.phoneNo;
    this.email;
    this.title;
}

function clearCache(customerId, cache) {
    if (cache) {
        sessionStorage.removeItem(cache + '_' + customerId);
        return true;
    }
    sessionStorage.clear();
}
