;(function (angular) {
    'use strict';

    angular.module('CamPlan.filters', [])
        .filter('country', ['Countries', function (Countries) {
            return function (code) {
                return Countries[code] || 'Unknown'
            }
        }])

        .filter('locale', ['Locales', function (Locales) {
            return function (code) {
                return Locales[code] || 'Unknown'
            }
        }])

        .filter('slice', function() {
            return function(arr, start, end) {
                return arr.slice(start, end);
            };
        })

        .filter('moment', [function () {
            return function (date, format) {
                return moment(date).format(format);
            }
        }])

        .filter('truncate', ['$filter', function ($filter) {
            return function (text, limit) {
                if (text.length > limit) {
                    return $filter('limitTo')(text, limit - 3) + '...';
                }

                return text;
            }
        }])

        .filter('granted', function() {
            return function(user, role) {
                if (user.roles == undefined) {
                    return false
                }

                return user.roles.indexOf(role) >= 0
            };
        })
        /**
         * Facet-like filtering of media.
         */
        .filter('advanced_filter', [function() {
            return function (mediaList, filters) {
                var updatedList = [];

                for (var i in mediaList) {
                    var medium = mediaList[i];

                    var addToList = true;

                    if (filters.categories.length) {
                        if (filters.categories.indexOf(medium.category) < 0) {
                            addToList = false;
                        }
                    }

                    if (filters.types.length) {
                        if (filters.types.indexOf(medium.type) < 0) {
                            addToList = false;
                        }
                    }

                    if (filters.locations.length) {
                        if (filters.locations.indexOf(medium.location.id) < 0) {
                            addToList = false;
                        }
                    }

                    if (filters.media.length) {
                        if (filters.media.indexOf(medium.title) < 0) {
                            addToList = false;
                        }
                    }
                    if (addToList) {
                        updatedList.push(medium);
                    }
                }
                return updatedList;
            }
        }])

        .filter('search', ['$filter', function ($filter) {
            return function (results, search) {
                if (search == undefined) {
                    return results;
                }

                var words = search.split(' ');

                angular.forEach(words, function (word) {
                    results = $filter('filter')(results, word);
                });

                return results;
            }
        }])

        .filter('can_book', ['$auth', function ($auth) {
            return function (Customer) {
                var user = $auth.getPayload();
                var userCustomer;

                if (user == undefined) {
                    return false;
                }

                if (user.administrator) {
                    return true;
                }

                angular.forEach(user.userCustomers, function (value, key) {
                    if (key == Customer.id) {
                        userCustomer = value;
                    }
                });

                if (!userCustomer) {
                    return false;
                }

                return userCustomer.editor || userCustomer.administrator
            }
        }])

        .filter('can_edit', ['$auth', function ($auth) {
            return function (Customer) {
                var user = $auth.getPayload();
                var userCustomer;

                if (user == undefined) {
                    return false;
                }

                if (user.administrator) {
                    return true;
                }

                angular.forEach(user.userCustomers, function (value, key) {
                    if (key == Customer.id) {
                        userCustomer = value;
                    }
                });

                if (!userCustomer) {
                    return false;
                }

                return userCustomer.administrator
            }
        }])

        .filter('measurement', [function () {
            return function (measurement) {
                if (measurement == undefined) {
                    return 'Unknown';
                }

                if (measurement.width != undefined && measurement.height != undefined) {
                    return measurement.width + 'x' + measurement.height + ' ' + measurement.unit;
                }

                return 'Unknown';
            }
        }])

        .filter('mediaType', ['TYPES', function (TYPES) {
            return function (type) {
                return TYPES[type] || 'Other';
            }
        }])

        .filter('mediaCategory', ['CATEGORIES', function (CATEGORIES) {
            return function (type) {
                return CATEGORIES[type] || 'Other';
            }
        }])

        .filter('isArchived', [function(){
            var getState = function(result, isArchived, list) {
                if (isArchived) {
                    if (result.disabled === isArchived || result.state == 'archived') {
                        list.push(result);
                    }
                } else {
                    if (result.disabled === isArchived && result.state != 'archived') {
                        list.push(result);
                    }
                }
            }
            return function (results, isArchived) {
                isArchived = !!isArchived;
                var filtered = [];

                for (var i in results) {
                    getState(results[i], isArchived, filtered);
                }
                return filtered;
            }
        }])
    ;
})(angular);
